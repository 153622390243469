import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

const Course = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "course-image-level-6.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      ico1: file(relativePath: { eq: "service_ico1.png" }) {
        childImageSharp {
          fixed(width: 74, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Course" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>
          New Zealand Diploma in Early Childhood Education and Care, Level 6
        </h1>
      </BackgroundImage>
      <div
        id="service_2"
        className="course__services bodhi__bg-pattern margin-header"
      >
        <div className="container">
          <div className="row perk">
            <div className="col-sm-12">
              <p>
                During the second year of your study with us, we complete the
                same four modules Biculturalism, Learning & Development,
                Inclusive Practice and Professional Practice, but it is a level
                above what was learnt within the Level 5 programme. It unpacks
                the learning theories further and the academic level is raised
                as you this is the equivalent to the second year of a Bachelor
                programme.
              </p>
            </div>
            <div className="col-sm-12">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScMXdeEifDxNsjoj94mfQW3kUIZ_hvtHvV0eN24HK2rz4UkEQ/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-margin-top"
                onClick={() => {
                  window.gtag("event", "apply_online_clicks", {
                    page_name: "level-6",
                    event_type: "apply-online-clicked",
                  })
                }}
              >
                Apply Online
              </a>
            </div>
          </div>
          <div className="row perk">
            <div className="col-sm-2 circle circle1">
              <Img
                fixed={data.ico1.childImageSharp.fixed}
                alt="Icon"
                className="icon-image"
              />
            </div>
            <div className="col-sm-10 description_wrapper">
              <div>
                <h5>
                  New Zealand Diploma in Early Childhood Education and Care
                  (Level 6) 120 Credits.
                </h5>
              </div>

              <div>
                <ul className="bodhi__indented-list">
                  <li>40 week full time course (35 hours per week).</li>
                  <li>2 x 2 week holidays.</li>
                  <li>Online via distance learning.</li>
                  <li>February & July Intake.</li>
                </ul>
                <p>
                  Field Based component
                  <br />
                  14 hours per week must take place in a licensed early
                  childhood service.
                  <br />
                  1 x 4 week in-home field based block (35 hours per week).
                  <br />1 x 4 week out of home field based block (35 hours per
                  week)
                </p>
              </div>
              <div>
                <br />
                <h5>Entry Criteria</h5>
                <ul className="bodhi__indented-list">
                  <li>
                    Applicants must have met entry criteria and completed the
                    New Zealand Diploma in Early Childhood Education and Care
                    (Level 5).
                  </li>
                </ul>
              </div>
              <div>
                <br />
                <h5>Fees (2023): $5480.00</h5>
              </div>
            </div>
          </div>
          <div className="row perk">
            <div className="col-sm-2"></div>
            <div className="col-sm-10 description_wrapper">
              <div>
                <div>
                  <h5>Pathways.</h5>
                  <p>
                    Bodhi recognises that to be recognised as a trained early
                    childhood educator, that the minimum level of study is a
                    Bachelor of Teaching (ECE) with a ITE provider. This is
                    Bodhi’s strategy goal and while we work toward it, we have
                    ensured our programmes align with the Teaching Council’s
                    teacher registration guidelines and our graduates can
                    seamlessly cross credit with another provider to complete
                    their third and final year of study to graduate with the
                    qualification required. It is our goal to scaffold our
                    learners so that they can confidently and competently
                    complete this third and final year with a bigger provider.
                    We are with our students the entire step of the way on this
                    incredible life changing journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row perk">
            <div className="col-sm-2"></div>
            <div className="col-sm-10 description_wrapper">
              <div>
                <div>
                  <h5>Field Based Definitions.</h5>
                  <p></p>
                  <p className="small">
                    Supervised: - the learner will have a suitable
                    qualified/experienced supervising/supporting mentor who will
                    assess the student’s practice against requirements. For
                    students in a licensed based service, supervision will be an
                    Associate Tutor belonging to the service, and a visiting
                    tutor from Bodhi. Those students in a home-based situation
                    will be mentored by an Associate Tutor from Bodhi Ltd, who
                    will also act as their visiting tutor.
                  </p>
                  <p className="small">
                    ECE setting – this must be a recognised early childhood
                    education (ECE) service, which may include a certificated
                    playgroup or a licensed centre-based service, parent-led
                    service, hospital-based service, home-based service; or
                    culturally-based environment. The home-based service may be
                    the child’s own home, or the home of the educator.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Course
